import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u004b\u0065\u0069\u006e\u0065\u0020\u0054\u0072\u0065\u0066\u0066\u0065\u0072\u0020\u0067\u0065\u0066\u0075\u006e\u0064\u0065\u006e",
        messages: {
            required: "\u0044\u0061\u0073\u0020\u0046\u0065\u006c\u0064\u0020\u0061\u0075\u0073\u0066\u00fc\u006c\u006c\u0065\u006e",
            phone: {
                incorrect: "\u0046\u0061\u006c\u0073\u0063\u0068\u0065\u0020\u0054\u0065\u006c\u0065\u0066\u006f\u006e\u006e\u0075\u006d\u006d\u0065\u0072",
            },
            email: {
                incorrect: "\u0046\u0061\u006c\u0073\u0063\u0068\u0065\u0073\u0020\u0041\u0064\u0072\u0065\u0073\u0073\u0066\u006f\u0072\u006d\u0061\u0074",
                symbolNotFound: "\u0044\u0069\u0065\u0020\u0041\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u006d\u0075\u0073\u0073\u0020\u0064\u0061\u0073\u0020\u005a\u0065\u0069\u0063\u0068\u0065\u006e\u0020\u201e\u0040\u201c\u0020\u0065\u006e\u0074\u0068\u0061\u006c\u0074\u0065\u006e\u002e\u0020\u0049\u006e\u0020\u0064\u0065\u0072\u0020\u0041\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u0066\u0065\u0068\u006c\u0074\u0020\u0064\u0061\u0073\u0020\u0053\u0079\u006d\u0062\u006f\u006c\u0020\u201e\u0040\u201c",
                addressIsNotFull: "\u0047\u0065\u0062\u0065\u006e\u0020\u0053\u0069\u0065\u0020\u0064\u0065\u006e\u0020\u0054\u0065\u0069\u006c\u0020\u0064\u0065\u0072\u0020\u0041\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u006e\u0061\u0063\u0068\u0020\u0064\u0065\u006d\u0020\u201e\u0040\u201c\u002d\u005a\u0065\u0069\u0063\u0068\u0065\u006e\u0020\u0065\u0069\u006e\u002e\u0020\u0044\u0069\u0065\u0020\u0041\u0064\u0072\u0065\u0073\u0073\u0065\u0020\u0069\u0073\u0074\u0020\u006e\u0069\u0063\u0068\u0074\u0020\u0076\u006f\u006c\u006c\u0073\u0074\u00e4\u006e\u0064\u0069\u0067\u002e",
            },
            password: {
                minLength: "\u0044\u0069\u0065\u0020\u004d\u0069\u006e\u0064\u0065\u0073\u0074\u0061\u006e\u007a\u0061\u0068\u006c\u0020\u0064\u0065\u0072\u0020\u0065\u0072\u006c\u0061\u0075\u0062\u0074\u0065\u006e\u0020\u005a\u0065\u0069\u0063\u0068\u0065\u006e\u0020\u0062\u0065\u0074\u0072\u00e4\u0067\u0074 6. \u004a\u0065\u0074\u007a\u0074 %current_length%",
            },
            repeatPassword: {
                discrepancy: "\u0044\u0069\u0065\u0020\u0065\u0069\u006e\u0067\u0065\u0067\u0065\u0062\u0065\u006e\u0065\u006e\u0020\u0050\u0061\u0073\u0073\u0077\u00f6\u0072\u0074\u0065\u0072\u0020\u0073\u0074\u0069\u006d\u006d\u0065\u006e\u0020\u006e\u0069\u0063\u0068\u0074\u0020\u00fc\u0062\u0065\u0072\u0065\u0069\u006e",
            },
            ofertaAgreement: {
                notChecked: "\u0042\u0065\u0073\u0074\u00e4\u0074\u0069\u0067\u0065\u006e\u0020\u0053\u0069\u0065\u0020\u0049\u0068\u0072\u0065\u0020\u005a\u0075\u0073\u0074\u0069\u006d\u006d\u0075\u006e\u0067\u0020\u007a\u0075\u0020\u0064\u0065\u006e\u0020\u0052\u0065\u0067\u0065\u006c\u006e",
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});